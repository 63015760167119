import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useToken from '../../Login/useToken'
import Login from '../../Login/Login'

export default function Create() {
    const { token, setToken } = useToken()

    const navigate = useNavigate()

    const [form, setForm] = useState({
        id: '',
        eventName: '',
        travelReq: '',
        destination: '',
        dates: '',
        transIndex: '0:0.0.0.0:0',
        next_jsp: 'index',
        userType: 'traveler',
        site: 'aspentravel',
        ssName: 'marketing_mela',
        ssKey: 'newuser',
        timestamp: Date.now()
    })

    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value }
        })
    }

    async function onSubmit(e) {
        e.preventDefault()

        const newForm = { ...form, id: `${form.timestamp}` }

        await fetch('https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/record', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(newForm),
        })
        .then(() => navigate('/Admin'))
        .catch(err => {
            window.alert(err)
            return
        })
    }

    if (!token) return (<Login setToken={setToken} />)

    return (
        <div className='flex flex-col justify-center items-center'>
            <h3 className='font-medium leading-tight text-xl my-2 text-gray-700'>Create New Form</h3>
            <form onSubmit={onSubmit} className='w-full max-w-lg'>
                <div className='flex flex-wrap -mx-3 mb-2'>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="eventName">
                            Event Name
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="eventName" 
                            type="text" 
                            placeholder="Event"
                            value={form.eventName}
                            onChange={e => updateForm({ eventName: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="travelReq">
                            Travel Requisition Number
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="travelReq" 
                            type="text" 
                            placeholder="Quest2023"
                            value={form.travelReq}
                            onChange={e => updateForm({ travelReq: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-6'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="destination">
                            Destination
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="destination" 
                            type="text" 
                            placeholder="AUS (Austin, Texas)"
                            value={form.destination}
                            onChange={e => updateForm({ destination: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-6'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="dates">
                            Dates
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="dates" 
                            type="text" 
                            placeholder="February 1-4, 2023"
                            value={form.dates}
                            onChange={e => updateForm({ dates: e.target.value })}
                            required />
                    </div>
                    
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="transIndex">
                            transIndex
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="transIndex" 
                            type="text" 
                            placeholder="0:0.0.0.0.0"
                            value={form.transIndex}
                            onChange={e => updateForm({ transIndex: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="transIndex">
                            next_jsp
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="next_jsp" 
                            type="text" 
                            placeholder="index"
                            value={form.next_jsp}
                            onChange={e => updateForm({ next_jsp: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="transIndex">
                            userType
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="userType" 
                            type="text" 
                            placeholder="traveler"
                            value={form.userType}
                            onChange={e => updateForm({ userType: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="site">
                            site
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="site" 
                            type="text" 
                            placeholder="melaleuca"
                            value={form.site}
                            onChange={e => updateForm({ site: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="ssName">
                            ssName
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="ssName" 
                            type="text" 
                            placeholder="ssName"
                            value={form.ssName}
                            onChange={e => updateForm({ ssName: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="ssKey">
                            ssKey
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="ssKey" 
                            type="text" 
                            placeholder="ssKey"
                            value={form.ssKey}
                            onChange={e => updateForm({ ssKey: e.target.value })}
                            required />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-2'>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <button className='w-1/2 text-white bg-gray-500 hover:bg-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors'
                            type='button'
                            onClick={() => navigate('/Admin')}>
                            Back
                        </button>

                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <button className='w-full bg-melaleuca-green hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors'
                            type='submit'>
                            Create Form
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useToken from '../../Login/useToken'

export default function EditAdmin() {
    const [admin, setAdmin] = useState({
        id: '',
        password: '',
        key: ''
    })
    const params = useParams()
    const navigate = useNavigate()
    const { token } = useToken()

    const [superAdmin, setSuperAdmin] = useState(true)

    useEffect(() => {
        const id = params.id.toString()
        if (!token || (JSON.parse(sessionStorage.getItem('token')).key !== '1669845462342' && JSON.parse(sessionStorage.getItem('token')).ca !== id)) navigate('/Admins')
        else {
            async function fetchData() {
                const response = await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/admin/${id}`)
    
                if (!response.ok) {
                    const message = `An error has occurred: ${response.statusText}`
                    window.alert(message)
                    return
                }
    
                const admin = await response.json()
                if (!admin) {
                    window.alert(`Record with id ${id} not found`)
                    navigate("/Admins")
                    return
                }
                if (admin.Item.key !== '1669845462342') setSuperAdmin(false)
                setAdmin(admin.Item)
            }
    
            fetchData()
            return
        }
    }, [params.id, navigate, token])

    async function deleteAdmin() {
        await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/admin/${params.id}`, {
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        })
        navigate('/Admins')
    }

    function updateAdmin(value) {
        return setAdmin((prev) => {
            return { ...prev, ...value }
        })
    }

    async function onSubmit(e) {
        e.preventDefault()

        const newAdmin = { ...admin }

        await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/admin/${params.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(newAdmin),
        })
        .catch(err => {
            window.alert(err)
            return
        })

        navigate('/Admins')
    }
    
    return (
        <div className='flex flex-col justify-center items-center'>
            <h3 className='font-medium leading-tight text-xl my-2 text-gray-700'>Edit Admin</h3>
            <form onSubmit={onSubmit} className='w-full max-w-lg'>
                <div className='flex flex-wrap -mx-3 mb-2'>
                    <div className='w-full px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="username">
                            Username
                        </label>
                        <input className='bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="username" 
                            type="text" 
                            placeholder="Username"
                            value={admin.id}
                            onChange={e => updateAdmin({ id: e.target.value })}
                            required 
                            disabled/>
                    </div>
                    <div className='w-full px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="password">
                            Password
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="password" 
                            type="password" 
                            placeholder="********"
                            value={admin.password}
                            onChange={e => updateAdmin({ password: e.target.value })}
                            required />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3'>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2 flex justify-between'>
                        <button className='w-1/2 text-white bg-gray-500 hover:bg-gray-700 font-medium rounded text-sm px-5 py-2.5 transition-colors'
                            type='button'
                            onClick={() => navigate('/Admins')}>
                            Back
                        </button>
                        <button className='w-5/12 text-white bg-red-500 hover:bg-red-700 font-medium rounded text-sm px-5 py-2.5 transition-colors disabled:bg-red-900'
                            onClick={deleteAdmin}
                            disabled={superAdmin}>
                            Delete
                        </button>
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <button className='w-full bg-melaleuca-green hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors'
                            type='submit'>
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
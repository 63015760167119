import React from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'

import Header from './components/App/Header'
import Home from './components/App/Home'
import Admin from './components/Admin/Admin'
import Admins from './components/Admin/Admins/Admins'
import Form from './components/Form/Form'
import Edit from './components/Admin/Form/Edit'
import EditAdmin from './components/Admin/Admins/EditAdmin'
import CreateAdmin from './components/Admin/Admins/CreateAdmin'
import Create from './components/Admin/Form/Create'
import Success from './components/Form/Success'


function App() {
  return (
      <div>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/Success' element={<Success />} />
          <Route exact path='/Form' element={<Home />} />
          <Route exact path='/Form/:id' element={<Form />} />
          <Route exact path='/Admin' element={<Admin />} />
          <Route exact path='/Admins' element={<Admins />} />
          <Route path='/Admin/Edit/:id' element={<Edit />} />
          <Route path='/Admin/Create' element={<Create />} />
          <Route path='/Admins/Edit/:id' element={<EditAdmin />} />
          <Route path='/Admins/Create' element={<CreateAdmin />} />
        </Routes>
      </div>
  )
}

export default App

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Admin = (props) => {
    let monthNames = [ "January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December" ]
    let d = 'Never'
    let s = (
        <div></div>
    )
    if (props.admin.lastLoggedIn !== undefined && props.admin.lastLoggedIn !== null) {
        let l = new Date(props.admin.lastLoggedIn)
        d = `${monthNames[l.getMonth()]} ${l.getDate()}, ${l.getFullYear()} ${l.toLocaleTimeString('en-US', { timeZone: 'America/Denver' })}`
    }
    if (props.super === true) {
        s = (
            <Link className='text-white bg-melaleuca-green hover:bg-green-800 font-medium rounded text-sm px-5 py-2.5 mr-2' 
                to={`/Admins/Edit/${props.admin.id}`}>
                &#9998;
            </Link>
        )
    }
    return (
    <tr className='border-b'>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>{props.admin.id}</td>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>************</td>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>{d}</td>
        <td>
            {s}
        </td>
    </tr>
)}

export default function AdminList() {
    const [admins, setAdmins] = useState([])
    const [superAdmin, setSuperAdmin] = useState(false)

    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem('token')).key === '1669845462342') setSuperAdmin(true)
        async function getAdmins() {
            const response = await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/admin`)

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`
                window.alert(message)
                return
            }

            const admins = await response.json()
            setAdmins(admins.sort((a, b) => (a.lastLoggedIn < b.lastLoggedIn) ? 1 : -1))
        }

        getAdmins()
        return
    }, [admins.length])

    function formList() {
        return admins.map((admin) => {
            let c = false
            if (JSON.parse(sessionStorage.getItem('token')).key === admin.key) c = true
            return (
                <Admin
                    admin={admin}
                    key={admin.id}
                    super={superAdmin || c}
                />
            )
        })
    }

    return (
        <div className='flex flex-col justify-center items-center'>
            <table className='w-full'>
                <thead className='bg-white border-b'>
                    <tr>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>Username</th>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>Password</th>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>Last Logged In</th>
                        <th className='text-left'>
                            <Link className='text-white bg-melaleuca-green hover:bg-green-800 rounded font-medium text-sm px-6 py-2.5 transition-colors' 
                                to={`/Admins/Create`}>
                                Create New &#43;
                            </Link>
                        </th>
                    </tr>
                </thead>
                <tbody className='striped'>
                    {formList()}
                </tbody>
            </table>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useToken from '../../Login/useToken'

export default function Edit() {
    const [form, setForm] = useState({
        eventName: '',
        travelReq: '',
        destination: '',
        dates: '',
        transIndex: '',
        next_jsp: '',
        userType: '',
        site: '',
        ssName: '',
        ssKey: '',
        timestamp: ''
    })
    const params = useParams()
    const navigate = useNavigate()
    const { token } = useToken()

    useEffect(() => {
        if (!token) navigate('/Admin')
        else {
            async function fetchData() {
                const id = params.id.toString()
                const response = await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/record/${id}`)
    
                if (!response.ok) {
                    const message = `An error has occurred: ${response.statusText}`
                    window.alert(message)
                    return
                }
    
                const form = await response.json()
                if (!form) {
                    window.alert(`Record with id ${id} not found`)
                    navigate("/Admin")
                    return
                }
    
                setForm(form.Item)
            }
    
            fetchData()
            return
        }
    }, [params.id, navigate, token])

    async function deleteForm() {
        await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/record/${params.id}`, {
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        })
        navigate('/Admin')
    }

    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value }
        })
    }

    async function onSubmit(e) {
        e.preventDefault()

        const newForm = { ...form }
        newForm.timestamp = Date.now()

        await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/record/${params.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(newForm),
        })
        .catch(err => {
            window.alert(err)
            return
        })

        navigate('/Admin')
    }

    return (
        <div className='flex flex-col justify-center items-center'>
            <h3 className='font-medium leading-tight text-xl my-2 text-gray-700'>Edit Form</h3>
            <form onSubmit={onSubmit} className='w-full max-w-lg'>
                <div className='flex flex-wrap -mx-3 mb-2'>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="eventName">
                            Event Name
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="eventName" 
                            type="text" 
                            placeholder="Event"
                            value={form.eventName}
                            onChange={e => updateForm({ eventName: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="travelReq">
                            Travel Requisition Number
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="travelReq" 
                            type="text" 
                            placeholder="Quest2023"
                            value={form.travelReq}
                            onChange={e => updateForm({ travelReq: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-6'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="destination">
                            Destination
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="destination" 
                            type="text" 
                            placeholder="AUS (Austin, Texas)"
                            value={form.destination}
                            onChange={e => updateForm({ destination: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-6'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="dates">
                            Dates
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="dates" 
                            type="text" 
                            placeholder="February 1-4, 2023"
                            value={form.dates}
                            onChange={e => updateForm({ dates: e.target.value })}
                            required />
                    </div>
                    
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="transIndex">
                            transIndex
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="transIndex" 
                            type="text" 
                            placeholder="0:0.0.0.0.0"
                            value={form.transIndex}
                            onChange={e => updateForm({ transIndex: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="transIndex">
                            next_jsp
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="next_jsp" 
                            type="text" 
                            placeholder="index"
                            value={form.next_jsp}
                            onChange={e => updateForm({ next_jsp: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="transIndex">
                            userType
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="userType" 
                            type="text" 
                            placeholder="traveler"
                            value={form.userType}
                            onChange={e => updateForm({ userType: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="site">
                            site
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="site" 
                            type="text" 
                            placeholder="melaleuca"
                            value={form.site}
                            onChange={e => updateForm({ site: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="ssName">
                            ssName
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="ssName" 
                            type="text" 
                            placeholder="ssName"
                            value={form.ssName}
                            onChange={e => updateForm({ ssName: e.target.value })}
                            required />
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="ssKey">
                            ssKey
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="ssKey" 
                            type="text" 
                            placeholder="ssKey"
                            value={form.ssKey}
                            onChange={e => updateForm({ ssKey: e.target.value })}
                            required />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3'>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2 flex justify-between'>
                        <button className='w-1/2 text-white bg-gray-500 hover:bg-gray-700 font-medium rounded text-sm px-5 py-2.5 transition-colors'
                            type='button'
                            onClick={() => navigate('/Admin')}>
                            Back
                        </button>
                        <button className='w-5/12 text-white bg-red-500 hover:bg-red-700 font-medium rounded text-sm px-5 py-2.5 transition-colors'
                            onClick={deleteForm}>
                            Delete
                        </button>
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <button className='w-full bg-melaleuca-green hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors'
                            type='submit'>
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
import React from 'react'
import Login from '../Login/Login'
import useToken from '../Login/useToken'
import List from './Form/List'

export default function Admin() {
    const { token, setToken } = useToken()

    if (!token) return (<Login setToken={setToken} />)

    return(
        <List />
    )
}
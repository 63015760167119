import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useToken from '../../Login/useToken'
import Login from '../../Login/Login'

export default function CreateAdmin() {
    const { token, setToken } = useToken()
    const [admin, setAdmin] = useState({
        id: '',
        password: '',
        key: Date.now()
    })

    const navigate = useNavigate()

    function updateAdmin(value) {
        return setAdmin((prev) => {
            return { ...prev, ...value }
        })
    }

    async function onSubmit(e) {
        e.preventDefault()

        const newAdmin = { ...admin }
        

        await fetch('https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/admin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(newAdmin),
        })
        .then(() => navigate('/Admins'))
        .catch(err => {
            window.alert(err)
            return
        })
    }

    if (!token) return (<Login setToken={setToken} />)
    if (JSON.parse(sessionStorage.getItem('token')).key !== '1669845462342') return (
        <div className='flex flex-col justify-center items-center'>
            <p>You must be a superadmin to create admins.</p>
        </div>
    )

    return (
        <div className='flex flex-col justify-center items-center'>
            <h3 className='font-medium leading-tight text-xl my-2 text-gray-700'>Create New Admin</h3>
            <form onSubmit={onSubmit} className='w-full max-w-lg'>
                <div className='flex flex-wrap -mx-3 mb-2'>
                    <div className='w-full px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="username">
                            Username
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="username" 
                            type="text" 
                            placeholder="Username"
                            value={admin.id}
                            onChange={e => updateAdmin({ id: e.target.value })}
                            required />
                    </div>
                    <div className='w-full px-3 mb-2'>
                        <label className="block mb-2 text-sm font-medium text-gray-900"
                            htmlFor="password">
                            Password
                        </label>
                        <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                            id="password" 
                            type="password" 
                            placeholder="********"
                            value={admin.password}
                            onChange={e => updateAdmin({ password: e.target.value })}
                            required />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3'>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2 flex justify-between'>
                        <button className='w-full text-white bg-gray-500 hover:bg-gray-700 font-medium rounded text-sm px-5 py-2.5 transition-colors'
                            type='button'
                            onClick={() => navigate('/Admins')}>
                            Back
                        </button>
                    </div>
                    <div className='w-1/2 md-w-1/2 px-3 mb-2'>
                        <button className='w-full bg-melaleuca-green hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors'
                            type='submit'>
                            Create
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

export default function Home() {
    const [code, setCode] = useState()
    const navigate = useNavigate()

    const handleSubmit = () => {
        navigate(`/Form/${code}`)
    }

    return (
        <div className='flex flex-col justify-center items-center'>
            <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mt-24' onSubmit={handleSubmit}>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='code'>
                        <input id='code' type="text" placeholder='Code' onChange={e => setCode(e.target.value)} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required/>
                    </label>
                </div>
                <div className='flex items-center justify-between'>
                    <button type="submit" className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}
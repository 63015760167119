import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Header() {
  const navigate = useNavigate()
  const clear = () => {
    sessionStorage.clear()
    document.cookie = 'loggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    navigate('/')
  }
  let rb = (
    <Link to='/Admin' className='self-center uppercase text-xl font-normal whitespace-nowrap bg-transparent hover:bg-gray-50 hover:bg-opacity-20 border-white tracking-wide border-2 text-white my-2 mx-4 rounded transition-colors'>
      <div className='w-full py-2 px-4 text-xs'>
        Admin
      </div>
    </Link>
  )

  if (window.location.href.toString().includes('/Form/')) {
    rb = (
      <div></div>
    )
  }

  if (window.location.href.toString().includes('/Admin')) {
    let v = `; ${document.cookie}`
    let p = v.split(`; loggedIn=`)
    let l = false
    if (p.length === 2) l = p.pop().split(';').shift()
    else rb = ( <div></div> )

    let page = ''
    let text = ''
    if (l === 'true') {
      if (window.location.href.toString().includes('/Admins')) {
        page = 'Admin'
        text = 'Forms'
      }
      else {
        page = text = 'Admins'
      }
      rb = (
        <div className='w-25 flex justify-between'>
          <Link to={`/${page}`} className='self-center uppercase text-xl font-normal whitespace-nowrap bg-transparent hover:bg-gray-50 hover:bg-opacity-20 border-white tracking-wide border-2 text-white my-2 mx-4 rounded transition-colors'>
            <div className='w-full py-2 px-4 text-xs'>
              {text}
            </div>
          </Link>
          <button onClick={clear} className='self-center uppercase text-xl font-normal whitespace-nowrap bg-transparent hover:bg-gray-50 hover:bg-opacity-20 border-white tracking-wide border-2 text-white my-2 mx-4 rounded transition-colors'>
            <div className='w-full py-2 px-4 text-xs'>
              Log Out
            </div>
          </button>
        </div>
      )
    }
  }

  return (
      <nav className='bg-melaleuca-green'>
        <div className='flex justify-between'>
          <Link to='/' className='self-center uppercase text-xl font-normal whitespace-nowrap bg-white hover:bg-gray-200 text-melaleuca-green tracking-wide my-2 mx-4 rounded transition-colors'>
            <div className='w-full py-2 px-4 text-xs'>
              Get There
            </div>
          </Link>
          {rb}
        </div>
      </nav>
  )
}
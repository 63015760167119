import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Form = (props) => {
    const initialText = 'Copy Link'
    const [buttonText, setButtonText] = useState(initialText)

    function handleClick() {
        try {
            navigator.clipboard.writeText(`https://melaleuca.sdw.dev/Form/${props.form.id}`)
        }
        catch {
            window.alert('Cannot copy on http, must be https')
        }
        setButtonText('Copied')
        setTimeout(() => {
            setButtonText(initialText);
        }, 3000)
    }

    let d = new Date(props.form.timestamp)
    return (
    <tr className='border-b'>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>{props.form.eventName}</td>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>{props.form.travelReq}</td>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>{props.form.destination}</td>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>{props.form.dates}</td>
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>{`${d.getFullYear()}.${d.getMonth()+1}.${d.getDate()}`}</td>
        <td>
            <Link className='text-white bg-melaleuca-green hover:bg-green-800 font-medium rounded text-sm px-5 py-2.5 mr-2' 
                to={`/Admin/Edit/${props.form.id}`}>
                &#9998;
            </Link>
            <button className='py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-600 hover:bg-gray-100 hover:text-melaleuca-green focus:bg-gray-200 '
                onClick={handleClick}>
                {buttonText}
            </button>
        </td>
    </tr>
)}

export default function List() {
    const [forms, setForms] = useState([])

    useEffect(() => {
        async function getForms() {
            const response = await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/record`)

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`
                window.alert(message)
                return
            }

            const f = await response.json()
            const forms = f.Items
            setForms(forms.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1))
        }

        getForms()
        return
    }, [forms.length])

    function formList() {
        return forms.map((form) => {
            return (
                <Form
                    form={form}
                    key={form.id}
                />
            )
        })
    }

    return (
        <div className='flex flex-col justify-center items-center'>
            <table className='w-full'>
                <thead className='bg-white border-b'>
                    <tr>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>Event Name</th>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>TRN</th>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>Destination</th>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>Dates</th>
                        <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>Last Updated</th>
                        <th className='text-left'>
                            <Link className='text-white bg-melaleuca-green hover:bg-green-800 rounded font-medium text-sm px-6 py-2.5 transition-colors' 
                                to={`/Admin/Create`}>
                                Create New &#43;
                            </Link>
                        </th>
                    </tr>
                </thead>
                <tbody className='striped'>
                    {formList()}
                </tbody>
            </table>
        </div>
    )
}
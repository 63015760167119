import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

export default function Login({ setToken }) {
    const navigate = useNavigate()
    const [id, setId] = useState()
    const [password, setPassword] = useState()
    const [error, setError] = useState(true)

    const loginUser = async (credentials) => {
        fetch('https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(credentials)
        })
        .then(data => data.json())
        .then((res) => {
            setToken(res)
            const d = new Date()
            d.setTime(d.getTime() + (60*60*1000))
            let e = `expires=${d.toUTCString()}`
            document.cookie = `loggedIn=true;${e};path=/`
            navigate('/Admin')
        })
        .catch(() => setError(false))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        await loginUser({
            id,
            password
        })
    }
    return(
        <div className='flex flex-col justify-center items-center'>
            <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mt-24' onSubmit={handleSubmit}>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='username'>
                        <input id='username' type="text" placeholder='Username' onChange={e => setId(e.target.value)} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                    </label>
                </div>
                <div className="mb-2">
                    <label className='block text-gray-700 text-sm font-bold' htmlFor='password'>
                        <input id='password' type="password" placeholder='*************' onChange={e => setPassword(e.target.value)} className='shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline' />
                    </label>
                </div>
                <div className="border border-red-400 rounded bg-red-100 px-4 py-3 text-red-700" hidden={error}>
                    <p>Invalid Credentials.</p>
                </div>
                <div className='flex items-center justify-between mt-2'>
                    <button type="submit" className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                        Sign In
                    </button>
                </div>
            </form>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

export default function Form() {
  const params = useParams()

  const [defaults, setDefaults] = useState({
    eventName: '',
    travelReq: '',
    destination: '',
    dates: '',
    transIndex: '',
    next_jsp: '',
    userType: '',
    site: '',
    ssName: '',
    ssKey: '',
    timestamp: '',
    user: '',
    pwd: 'travel',
    pwd2: 'travel',
    firstName: '',
    middleInitial: '',
    lastName: '',
    email: '',
    requestType: 'portalaccess',
    portalRequestType: 'createaccount',
  })

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`https://fyxwg91s47.execute-api.us-west-2.amazonaws.com/record/${params.id}`)
  
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`
        return (
          <div className='flex flex-col justify-center items-center'>
            <p>{message}</p>
          </div>
        )
      }
  
      const defaults = await response.json()
      if (!defaults) {
        return (
          <div className='flex flex-col justify-center items-center'>
            <p>Record with id {params.id} not found</p>
          </div>
        )
      }
      setDefaults(defaults.Item)
    }

    fetchData()
    return
  }, [params.id, navigate])

  function updateForm(value) {
    return setDefaults((prev) => {
      return { ...prev, ...value, user: value.email }
    })
  }

  function toggleLogin() {
    let FirstSection = document.querySelector('#First')
    let MiddleSection = document.querySelector('#Middle')
    let LastSection = document.querySelector('#Last')

    let First = document.querySelector('#firstName')
    let Last = document.querySelector('#lastName')
    let login = document.querySelector('#login')
    if (defaults.portalRequestType === 'createaccount' || defaults.portalRequestType === undefined) {
      FirstSection.style.display = MiddleSection.style.display = LastSection.style.display = 'none'
      First.required = Last.required = false
      login.innerHTML = 'Click here to register instead.'
      setDefaults({ ...defaults, portalRequestType: 'login' })
    }
    else {
      FirstSection.style.display = MiddleSection.style.display = LastSection.style.display = 'block'
      First.required = Last.required = true
      login.innerHTML = 'Already have an account? Login instead.'
      setDefaults({ ...defaults, portalRequestType: 'createaccount' })
    }
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <h3 className='font-medium leading-tight text-3xl mt-2 text-gray-700'>{defaults.eventName}</h3>
      <h3 className='font-medium leading-tight text-sm mb-4 text-gray-700'>{defaults.dates} | {defaults.destination}</h3>
      <p className="text-center text-xs mb-2 mx-3">Enter your name EXACTLY as it appears on your valid Driver's License or Passport</p>
      <span onClick={() => toggleLogin()} id="login" className="text-center text-md mb-2 text-melaleuca-green cursor-pointer underline hover:text-green-900">Already have an account? Login instead.</span>
      <form method='POST' action='https://wx1.getthere.net/DispatcherServlet' className='w-full max-w-lg'>
        <div className='flex flex-wrap mx-3'>
          <div className='w-full md-w-1/2 px-3 mb-2 grid grid-cols-3 gap-2'>
            <input type="hidden" name="requestType" value={defaults.requestType} />
            <input type="hidden" name="portalRequestType" value={defaults.portalRequestType} />
            <input type="hidden" name="transIndex" value={defaults.transIndex} />
            <input type="hidden" name="next_jsp" value={defaults.next_jsp} />
            <input type="hidden" name="travel_request_number" value={defaults.travelReq} />
            
            <input type="hidden" name="userType" value={defaults.userType} />
            <input type="hidden" name="site" size="17" maxLength="48" value={defaults.site} />
            <input type="hidden" name="ssName" size="17" maxLength="48" value={defaults.ssName} />
            <input type="hidden" name="ssKey" size="17" maxLength="48" value={defaults.ssKey} />
            <input type="hidden" name="pwd" size="17" maxLength="48" value={defaults.pwd} />
            <input type="hidden" name="pwd2" size="17" maxLength="48" value={defaults.pwd} />
            <input type="hidden" name="user" value={defaults.user} />
            <div className="w-full col-span-2" id="First">
              <label className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="firstName">
                  First Name <em style={{color: 'darkred'}}>*</em>
              </label>
              <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                  id="firstName"
                  type="text" 
                  name="firstName"
                  placeholder="First Name"
                  value={defaults.firstName}
                  onChange={e => updateForm({ firstName: e.target.value })}
                  required />
            </div>
            <div className="w-full" id="Middle">
              <label className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="middleInitial">
                  Middle Initial
              </label>
              <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                  id="middleInitial"
                  type="text" 
                  name="middleInitial"
                  placeholder="Middle Initial"
                  maxLength="1"
                  value={defaults.middleInitial}
                  onChange={e => updateForm({ middleInitial: e.target.value })} />
            </div>
          </div>
          <div className='w-full md-w-1/2 px-3 mb-2' id="Last">
            <label className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="lastName">
                Last Name <em style={{color: 'darkred'}}>*</em>
            </label>
            <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                id="lastName"
                type="text" 
                name="lastName"
                placeholder="Last Name"
                value={defaults.lastName}
                onChange={e => updateForm({ lastName: e.target.value })}
                required />
          </div>
          <div className='w-full md-w-1/2 px-3 mb-2'>
            <label className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="email">
                Email <em style={{color: 'darkred'}}>*</em>
            </label>
            <input className='bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2'
                id="email"
                type="email" 
                name="email"
                placeholder="Email"
                value={defaults.email}
                onChange={e => updateForm({ email: e.target.value })}
                required />
          </div>
        </div>
        <div className='flex flex-row justify-center items-center mt-4'>
          <button className='w-1/2 bg-melaleuca-green hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors'
              type='submit'>
              Submit
          </button>
        </div>
      </form>
    </div>
  )
}
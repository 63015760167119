import React from 'react'
import Login from '../../Login/Login'
import useToken from '../../Login/useToken'
import AdminList from './AdminList'

export default function Admins() {
    const { token, setToken } = useToken()

    if (!token) return (<Login setToken={setToken} />)

    return(
        <AdminList />
    )
}